/* eslint-disable no-console */
import { AwsRum } from 'aws-rum-web'

// Hard-coded for now
const awsOptions = {
	appMonitorEndpoint: 'https://dataplane.rum.eu-west-1.amazonaws.com',
	appMonitorId: 'd34a4fde-850f-4b63-9349-79e55abda7f4',
	appMonitorRegion: 'eu-west-1',
	identityPoolId: 'eu-west-1:604a01f5-4825-430f-bf48-6834c0f3243b',
}

// Hard-coded for now
const version = '0.0.0'

export class MetricsClient {
	/** @type {null | AwsRum} */
	#rum = null

	constructor(options) {
		const { hostname, sampleRate, systemCode } = MetricsClient.#defaultOptions(options)

		const rumConfig = {
			sessionSampleRate: sampleRate,
			identityPoolId: awsOptions.identityPoolId,
			endpoint: awsOptions.appMonitorEndpoint,
			telemetries: ['errors'],
			allowCookies: false,
			enableXRay: false,
			disableAutoPageView: true,
			sessionAttributes: { systemCode },
		}
		try {
			this.#rum = new AwsRum(awsOptions.appMonitorId, version, awsOptions.appMonitorRegion, rumConfig)
			// RUM only works when running on an *.ft.com domain
			if (!/\.ft\.com$/.test(hostname)) {
				console.warn(`Client errors cannot be handled on ${hostname}`)
				this.#rum.disable()
			}
		} catch (error) {
			console.warn(`RUM client could not be initialised: ${error.message}`)
		}
	}

	recordError(error) {
		return this.#rum?.recordError(error)
	}

	recordEvent(namespace, eventData) {
		this.#rum?.recordEvent(MetricsClient.#resolveNamespace(namespace), eventData)
	}

	static #resolveNamespace(namespace: string) {
		return `com.ft.${namespace}`
	}

	static #defaultOptions(options) {
		return Object.assign(
			{
				hostname: window?.location?.hostname || 'localhost',
				sampleRate: 0, // 0%
				systemCode: null,
			},
			options,
		)
	}
}
