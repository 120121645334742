import * as nTracking from '@financial-times/n-tracking'

function extractExtraInfo(event) {
	const parentClassNames = event?.srcElement?.parentNode?.classList
	const [headline] = event?.srcElement?.childNodes[0]?.querySelectorAll('div[data-content-id]')
	const teaserId = headline?.getAttribute('data-content-id')

	return { parentClassNames, teaserId }
}

function trackAction(action, context = {}, event) {
	const { parentClassNames, teaserId } = extractExtraInfo(event)
	const data = Object.assign({ parentClassNames, teaserId }, context)
	nTracking.broadcast('oTracking.event', {
		category: 'custom-code-component',
		action,
		...data,
	})
}

function onConnected(event) {
	console.info('next-home-page: custom code component connected', event)
	trackAction('connected', { detail: event?.detail }, event)
}

function onError(event) {
	console.error('next-home-page: custom code component failed to load', event)
	trackAction('failed', { reason: event?.message, detail: event?.detail }, event)
}

function onTimeout(event) {
	console.error('next-home-page: custom code component timeout', event)
	trackAction('timeout', { detail: event?.detail }, event)
}

export function initTracking() {
	const customCodeComponents = document?.querySelectorAll('custom-code-component')
	if (!customCodeComponents?.length) {
		return
	}

	for (let i = 0; i < customCodeComponents.length; i++) {
		customCodeComponents[i]?.addEventListener('error', onError)
		customCodeComponents[i]?.addEventListener('ccc-timeout', onTimeout)
		customCodeComponents[i]?.addEventListener('ccc-connected', onConnected)
	}
}
